// eslint-disable-next-line @typescript-eslint/no-var-requires
const styles = require("./src/scss/styles.scss");
exports.shouldUpdateScroll = ({routerProps: { location }}) => {
  if (location.hash) {
    // Disable default saving of scroll position in gatsby and scroll should happen like normal HTML site.
    setTimeout(() => {
      const el = document.querySelector(location.hash);
      const elRect = el?.getBoundingClientRect();
      window.scrollTo(0, elRect?.top + window.scrollY)
    }, 1000)
    return false
  }
  else {
    // Disable saving of scroll position on normal urls as well.
    window.scrollTo(0, 0)
    return false
  }
}
exports.onRouteUpdate = () => {
  const skipLink = document.querySelector("#skip-link a")
  if (skipLink) {
    // Skip link should receive the focus on page load.
    skipLink.focus()
    // It shall not be visible at first.
    skipLink?.setAttribute('style', 'opacity:0;')
    document.addEventListener('keydown', (e: any) => {
      // Any subsequent key press shall return the skip link to normal behaviour.
      if (e.key==='Tab' || e.key==='Enter' || e.key==='Space') {
        skipLink?.setAttribute('style', '')
      }
    })
  }
}