exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-global-article-index-tsx": () => import("./../../../src/templates/global/article/index.tsx" /* webpackChunkName: "component---src-templates-global-article-index-tsx" */),
  "component---src-templates-global-cookie-policy-index-tsx": () => import("./../../../src/templates/global/cookie-policy/index.tsx" /* webpackChunkName: "component---src-templates-global-cookie-policy-index-tsx" */),
  "component---src-templates-global-formpages-index-tsx": () => import("./../../../src/templates/global/formpages/index.tsx" /* webpackChunkName: "component---src-templates-global-formpages-index-tsx" */),
  "component---src-templates-hcp-cft-index-tsx": () => import("./../../../src/templates/hcp/CFT/index.tsx" /* webpackChunkName: "component---src-templates-hcp-cft-index-tsx" */),
  "component---src-templates-hcp-es-index-tsx": () => import("./../../../src/templates/hcp/ES/index.tsx" /* webpackChunkName: "component---src-templates-hcp-es-index-tsx" */),
  "component---src-templates-hcp-gtb-index-tsx": () => import("./../../../src/templates/hcp/GTB/index.tsx" /* webpackChunkName: "component---src-templates-hcp-gtb-index-tsx" */),
  "component---src-templates-hcp-home-index-tsx": () => import("./../../../src/templates/hcp/home/index.tsx" /* webpackChunkName: "component---src-templates-hcp-home-index-tsx" */),
  "component---src-templates-hcp-ird-index-tsx": () => import("./../../../src/templates/hcp/IRD/index.tsx" /* webpackChunkName: "component---src-templates-hcp-ird-index-tsx" */),
  "component---src-templates-hcp-syp-index-tsx": () => import("./../../../src/templates/hcp/SYP/index.tsx" /* webpackChunkName: "component---src-templates-hcp-syp-index-tsx" */),
  "component---src-templates-patient-cr-index-tsx": () => import("./../../../src/templates/patient/CR/index.tsx" /* webpackChunkName: "component---src-templates-patient-cr-index-tsx" */),
  "component---src-templates-patient-es-index-tsx": () => import("./../../../src/templates/patient/ES/index.tsx" /* webpackChunkName: "component---src-templates-patient-es-index-tsx" */),
  "component---src-templates-patient-gtb-index-tsx": () => import("./../../../src/templates/patient/GTB/index.tsx" /* webpackChunkName: "component---src-templates-patient-gtb-index-tsx" */),
  "component---src-templates-patient-home-index-tsx": () => import("./../../../src/templates/patient/home/index.tsx" /* webpackChunkName: "component---src-templates-patient-home-index-tsx" */),
  "component---src-templates-patient-ird-index-tsx": () => import("./../../../src/templates/patient/IRD/index.tsx" /* webpackChunkName: "component---src-templates-patient-ird-index-tsx" */),
  "component---src-templates-patient-stgt-index-tsx": () => import("./../../../src/templates/patient/STGT/index.tsx" /* webpackChunkName: "component---src-templates-patient-stgt-index-tsx" */)
}

